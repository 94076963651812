import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { Fade, Grow } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Product from '../../Components/Product/Product.js'
import './catalog.css'
import { useSelector, useDispatch } from 'react-redux';
import { goToMain } from '../../Store/Actions.js'
import DIY from '../../Components/DIY/DIY.js'
import products from '../../Store/Products.js'

const useStyles = makeStyles(theme => ({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const Catalog = () => {

    const classes = useStyles();
    const [expended, setExpanded] = useState(null)
    const [isDiy, setIsDiy] = useState(false)
    const isMainPage = useSelector(state => state.isMainPage)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(goToMain(false))
    }, [])

    useEffect(() => {
        setExpanded(null)
        setIsDiy(false)
        dispatch(goToMain(false))
    }, [isMainPage])

    return (
        <div id='catalog'>
            <div className='products'>
                {!isDiy ?
                    expended == null ?
                        products.map((product, i) => {
                            return (
                                <Fade in={true}
                                    timeout={1000}>
                                    <div key={i} className='product' onClick={() => setExpanded(product)}>
                                        <Card className={classes.root}>
                                            <CardMedia
                                                className={classes.media}
                                                image={product.image}
                                            />
                                            <CardHeader
                                                title={product.title}
                                                subheader={product.description}
                                            />
                                        </Card>
                                    </div>
                                </Fade>
                            )
                        })
                        : <Product product={expended}></Product> : <DIY productTypes={products}></DIY>
                }
                {!isDiy ?
                    <Fade in={true}
                        timeout={1000}><div onClick={() => setIsDiy(true)} className='product'>
                            <Card className={classes.root}>
                                <CardMedia
                                    className={classes.media}
                                    image={require('../../assets/Products/diy.jpg')}
                                />
                                <CardHeader
                                    title='DIY'
                                    subheader='רקמה בהתאמה'
                                />
                            </Card>
                        </div>
                    </Fade> : null}
            </div>
        </div>
    );
}

export default Catalog