import React, { useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardMedia,
    IconButton,
    CardContent,
    TextField,
    InputLabel,
    Select,
    FormControl,
    CardActions,
    MenuItem,
    Slider,
    Typography,
    InputBase,
    Avatar,
    Switch,
    Grid
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import BorderHorizontalIcon from '@material-ui/icons/BorderHorizontal'
import BorderVerticalIcon from '@material-ui/icons/BorderVertical';
import './diy.css'
import { addToCart } from '../../Store/Actions.js'
import { useDispatch, useSelector } from 'react-redux';
import zippers from '../../Store/Zippers.js'
import fabrics from '../../Store/Fabrics.js'
import stripes from '../../Store/Stripes.js'

const DIY_CODE = "888:"

const AntSwitch = withStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const useStyles = makeStyles(theme => ({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const PRICE_PER_WORD = 5
const DIY = (props) => {

    const classes = useStyles();
    const [selectedFabric, setSelectedFabric] = useState(fabrics[0])
    const [selectedStripe, setSelectedStripe] = useState(stripes[0])
    const [selectedZipper, setSelectedZipper] = useState(zippers[0])
    const [selectedProductType, setSelectedProductType] = useState(props.productTypes[0])
    const [isHorizonal, setIsHorizonal] = useState(true)
    const [text, setText] = useState(null)
    const cart = useSelector(state => state.cart)
    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='diy'>
            <Card className={classes.root}>
                <CardHeader
                    title='DIY'
                    subheader='רקמה בהתאמה'
                />
                <CardContent>
                    <CardHeader className='field'
                        subheader='בחר/י צבע בד'
                    />
                    <div className='templates'>
                        {
                            fabrics.map((item, i) => {
                                return (
                                    i + 1 < fabrics.length ?
                                        <div key={i} className={`template ${selectedFabric.name == item.name ? 'selected' : ''}`}
                                            style={{ backgroundImage: `url(${item.image})` }}
                                            onClick={() => setSelectedFabric(item)}>
                                        </div> : null
                                )
                            })
                        }
                    </div>
                </CardContent>
                <CardContent>
                    <CardHeader className='field'
                        subheader='בחר/י רקמה'
                    />
                    <div className='templates'>
                        {
                            stripes.map((item, i) => {
                                return (
                                    <div key={i} className={`template ${selectedStripe.name == item.name ? 'selected' : ''}`}
                                        style={{ backgroundImage: `url(${item.image})` }}
                                        onClick={() => setSelectedStripe(item)}>
                                    </div>
                                )
                            })
                        }
                    </div>
                </CardContent>
                <CardContent>
                    <CardHeader className='field'
                        subheader='בחר/י סוג'
                    />
                    <div className='templates'>
                        {
                            props.productTypes.map((item, i) => {
                                return (
                                    i + 1 < props.productTypes.length ?
                                        <div key={i} className={`template type ${selectedProductType.type == item.type ? 'selected' : ''}`}
                                            style={{ backgroundImage: `url(${item.image})` }}
                                            onClick={() => setSelectedProductType(item)}>
                                            {item.singleHeb}
                                        </div> : null
                                )
                            })
                        }
                    </div>
                </CardContent>
                <CardContent className='align'>
                    <CardHeader className='field'
                        subheader='בחר/י עימוד רקמה'
                    />
                    <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <BorderVerticalIcon />
                            <Grid item>מאונך</Grid>
                            <Grid item>
                                <AntSwitch checked={isHorizonal} onChange={() => setIsHorizonal(!isHorizonal)} name="checkedC" />
                            </Grid>
                            <Grid item>מאוזן</Grid>
                            <BorderHorizontalIcon />
                        </Grid>
                    </Typography>
                </CardContent>
                {
                    selectedProductType.isZipper ?
                        <CardContent>
                            <CardHeader className='field'
                                subheader='בחר/י צבע רוכסן'
                            />
                            <div className='templates'>
                                {
                                    zippers.map((item, i) => {
                                        return (
                                            <div key={i} className={`template ${selectedZipper.name == item.name ? 'selected' : ''}`}
                                                style={{ backgroundColor: item.color }}
                                                onClick={() => setSelectedZipper(item)}>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </CardContent> : null
                }
                {text != null ?
                    <CardActions className='text-field'>
                        <TextField onChange={(e) => setText(e.target.value)} id="standard-basic" label="רקמה בהקדשה" />
                    </CardActions> : null
                }
                <CardHeader className='price'
                    subheader={`${text ? selectedProductType.price + text.split(' ').filter((word) => word != ' ').length * PRICE_PER_WORD : selectedProductType.price} ₪`}
                />
                <CardActions disableSpacing>
                    <IconButton aria-label="share"
                        className={cart.filter((item) => item.id == (DIY_CODE + (cart.length - 1))).length > 0 ? 'with-text' : ''}
                        onClick={() => dispatch(addToCart(DIY_CODE + cart.length, selectedStripe.name, selectedZipper.name, text, selectedFabric.name, selectedProductType.type, null, selectedProductType.price))}>
                        {cart.filter((item) => item.id == (DIY_CODE + (cart.length - 1))).length == 0 ? <ShoppingCartIcon /> : <AddShoppingCartIcon />}
                    </IconButton>
                    <IconButton className={text ? 'with-text' : ''} onClick={() => setText(text == null ? '' : null)}>
                        <TextFieldsIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </div >
    );
}

export default DIY