export const cart = (cart = [], action) => {
    switch (action.type) {
        case 'ADD_TO_CART': {
            let item = cart.filter((item) => item.id == action.id && item.text == action.text)
            if (item.length == 0) {
                cart = [...cart,
                {
                    id: action.id,
                    stripe: action.stripe,
                    zipper: action.zipper,
                    text: action.text,
                    fabric: action.fabric,
                    product: action.product,
                    image: action.image,
                    price: action.price,
                    quantity: 1
                }]
            }
            else {
                cart.forEach((c, i) => {
                    if (c.id == action.id && c.text == action.text) {
                        cart[i].quantity = cart[i].quantity + 1
                    }
                })
            }
            cart = [...cart]
            localStorage.setItem('cart', JSON.stringify(cart))
            return cart
        }
        case 'REMOVE_FROM_CART': {
            let itemToRemove = cart.filter((item) => item.id == action.id && item.text == action.text)[0]
            if(itemToRemove.quantity == 1) {
                cart = cart.filter((item) => item != itemToRemove)
            } else {
                cart.forEach((c, i) => {
                    if (c.id == action.id && c.text == action.text) {
                        cart[i].quantity = cart[i].quantity - 1
                    }
                })
            }
            cart = [...cart]
            localStorage.setItem('cart', JSON.stringify(cart))
            return cart
        }
        default:
            return cart
    }
}

export const isMainPage = (isMainPage = true, action) => {
    switch (action.type) {
        case 'GO_TO_MAIN':
            isMainPage = action.flag
            return isMainPage
        default:
            return isMainPage
    }
}

export default function combined(state = {}, action) {
    return {
        cart: cart(state.cart, action),
        isMainPage: isMainPage(state.isMainPage, action)
    }
}