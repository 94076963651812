import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TextFieldsIcon from '@material-ui/icons/TextFields'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import './product.css'
import { addToCart } from '../../Store/Actions.js'
import { useDispatch, useSelector } from 'react-redux';
import fabrics from '../../Store/Fabrics.js'
import stripes from '../../Store/Stripes.js'
import allItems from '../../Store/Items.js'
import { Fade, Grow } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const PRICE_PER_WORD = 5
const Product = (props) => {

    const classes = useStyles();

    const [typedItems, setTypedItems] = useState(allItems.filter((item) => item.type == props.product.type))
    const [selected, setSelected] = useState(typedItems[0])
    const [selectedFabric, setSelectedFabric] = useState(fabrics.filter((fab) => fab.name == selected.fabric)[0])
    const [selectedStripe, setSelectedStripe] = useState(stripes.filter((str) => str.name == selected.stripe)[0])
    const [text, setText] = useState(null)
    const [available, setAvailable] = useState(false)
    const cart = useSelector(state => state.cart)
    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setSelected(typedItems.filter((item) => item.fabric == selectedFabric.name && item.stripe == selectedStripe.name)[0])
    }, [selectedStripe])

    useEffect(() => {
        const item = typedItems.filter((item) => item.fabric == selectedFabric.name)[0]

        if (stripes.filter((str) => item.stripe == str.name)[0] == selectedStripe) {
            setSelectedStripe(stripes.filter((str) => item.stripe == str.name)[0])
            setSelected(typedItems.filter((item) => item.fabric == selectedFabric.name && item.stripe == selectedStripe.name)[0])
        } else {
            setSelectedStripe(stripes.filter((str) => item.stripe == str.name)[0])
        }
    }, [selectedFabric])

    const setSelectedProduct = (product) => {
        setSelectedStripe(stripes.filter((str) => product.stripe == str.name)[0])
        setSelectedFabric(fabrics.filter((fbr) => product.fabric == fbr.name)[0])
        setSelected(typedItems.filter((item) => item.fabric == product.fabric && item.stripe == product.stripe)[0])
    }

    useEffect(() => {
        setAvailable(selectedStripe.avilability)
    }, [selectedStripe])

    return (
        <Fade in={true}
            timeout={1000}>
            <div className='expended'>
                <Card className={classes.root}>
                    <CardHeader
                        title={props.product.title}
                        subheader={props.product.description}
                    />
                    <CardMedia
                        className={`${classes.media} main-product ${available ? '' : 'not-available'}`}
                        image={selected.image}>
                        {available ? '' : 'לא במלאי'}
                    </CardMedia>
                    <CardContent>
                        <CardHeader className='field'
                            subheader='בחר/י צבע בד'
                        />
                        <div className='templates'>
                            {
                                fabrics.map((item, i) => {
                                    return (
                                        typedItems.filter((tItem) => tItem.fabric == item.name).length > 0 ?
                                            <Fade in={true}
                                                timeout={1000}><div key={i} className={`template ${selectedFabric.name == item.name ? 'selected' : ''}`}
                                                    style={{ backgroundImage: `url(${item.image})` }}
                                                    onClick={() => setSelectedFabric(item)}>
                                                </div></Fade> : null
                                    )
                                })
                            }
                        </div>
                    </CardContent>
                    <CardContent>
                        <CardHeader className='field'
                            subheader='בחר/י רקמה'
                        />
                        <div className='templates'>
                            {
                                stripes.map((item, i) => {
                                    return (
                                        typedItems.filter((tItem) => tItem.stripe == item.name && selectedFabric.name == tItem.fabric).length > 0 ?

                                            <Fade in={true}
                                                timeout={1000}><div key={i} className={`template ${selectedStripe.name == item.name ? 'selected' : ''} ${item.avilability ? '' : 'not-available'}`}
                                                    style={{ backgroundImage: `url(${item.image})` }}
                                                    onClick={() => setSelectedStripe(item)}>
                                                    {item.avilability ? '' : 'לא במלאי'}
                                                </div></Fade> : null
                                    )
                                })
                            }
                        </div>
                    </CardContent>
                    {text != null ?
                        <CardActions className='text-field'>
                            <TextField onChange={(e) => setText(e.target.value)} id="standard-basic" label="רקמה בהקדשה" />
                        </CardActions> : null
                    }
                    {selected.price ? <CardHeader className='price'
                        subheader={`${text ? selected.price + text.split(' ').filter((word) => word != ' ').length * PRICE_PER_WORD : selected.price} ₪`}
                    /> : null}
                    <CardActions disableSpacing>
                        {selected.type != 'hoops' ? <IconButton aria-label="share"
                            className={cart.filter((prod) => prod.stripe == selected.stripe &&
                                prod.fabric == selected.fabric && prod.type == selected.product).length > 0 ? 'with-text' : '' + available ? '' : ' not-available'} onClick={() => setText(text == null ? '' : null)}
                            onClick={available && selected.type != 'hoops' ? () => dispatch(addToCart(selected.id, selected.stripe, selected.zipper, text, selected.fabric, selected.type, selected.image, selected.price)) : () => null}>
                            {cart.filter((item) => item.id == selected.id).length == 0 ? <ShoppingCartIcon style={{ color: available ? 'unset' : 'lightgrey' }} /> : <AddShoppingCartIcon />}
                        </IconButton> : null}
                        <IconButton className={text ? 'with-text' : ''} onClick={() => setText(text == null ? '' : null)}>
                            <TextFieldsIcon />
                        </IconButton>
                    </CardActions>
                </Card>
                <div id='catalog'>
                    <div className='products'>
                        {
                            allItems.map((product, i) => {
                                return (
                                    product.type == props.product.type ?
                                        <Fade in={true}
                                            timeout={1000}><div key={i} onClick={() => setSelectedProduct(product)} className='product'>
                                                <Card className={classes.root}>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={product.image}
                                                    />
                                                    <CardHeader
                                                        title={`${product.price} ₪`}
                                                    />
                                                </Card>
                                            </div></Fade>
                                        : null
                                )
                            })

                        }
                    </div>
                </div>
            </div >
        </Fade>
    );
}

export default Product