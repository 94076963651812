import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    Avatar,
    TextField,
    Link,
    ListItemText,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { blue, grey } from '@material-ui/core/colors';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PaymentIcon from '@material-ui/icons/Payment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook'
import './infoDialog.css'

const useStyles = makeStyles({
    avatar: {
        backgroundColor: grey[100],
        color: grey[600],
    },
});

const InfoDialog = (props) => {
    const classes = useStyles();

    return (
        <div className='hello-dialog'>
            <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>
                <DialogContent className='title-content'>
                    <Typography className='pink' style={{ paddingRight: 2 }} variant="h6">רקמה</Typography>
                    <Typography variant="h6"> אודות</Typography>
                </DialogContent>
                <DialogContent className='text-content' dividers>
                    <List>
                        <ListItem className='field' style={{ alignItems: 'center', textAlign: 'center' }} button>
                            <Avatar className={classes.avatar}>
                                <PhoneIphoneIcon />
                            </Avatar>
                            <ListItemText variant='h4'>יש שאלות? מוזמנים בשמחה לפנות אלי בפלאפון 0584004483 או במייל rikmabymiki@gmail.com</ListItemText>
                        </ListItem>
                        <ListItem className='field' style={{ alignItems: 'center', textAlign: 'center' }} button>
                            <Avatar className={classes.avatar}>
                                <PaymentIcon />
                            </Avatar>
                            <ListItemText variant='h4'>תשלום יתבצע  לאחר שתקבלו התראה שההזמנה שלכם התקבלה במערכת! ניתן לשלם באמצעות העברה בנקאית, או באחת מאפליקציות התשלום המהיר </ListItemText>
                        </ListItem>
                        <ListItem className='field' style={{ alignItems: 'center', textAlign: 'center' }} button>
                            <Avatar className={classes.avatar}>
                                <LocalShippingIcon />
                            </Avatar>
                            <ListItemText variant='h4'>אפשרות לאיסוף עצמי מפתח תקווה או משלוח לאיזור תל אביב והמרכז ב₪30</ListItemText>
                        </ListItem>
                        <ListItem style={{ justifyContent: 'center' }}>
                            <Avatar style={{ marginRight: 10 }} className='instagram'>
                                <Link href="https://www.instagram.com/_rikma/">
                                    <InstagramIcon />
                                </Link>
                            </Avatar>
                            <Avatar className='facebook'>
                                <Link href="https://www.facebook.com/rikmathings/?ref=bookmarks">
                                    <FacebookIcon />
                                </Link>
                            </Avatar>
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        </div >
    );
}

export default InfoDialog