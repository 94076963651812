import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import Logo from './assets/logo.png'
import Catalog from './Pages/Catalog/Catalog.js'
import Order from './Pages/Order/Order.js'
import Avatar from '@material-ui/core/Avatar'
import { goToMain } from './Store/Actions.js'
import HelloDialog from './Components/HelloDialog/HelloDialog.js'
import InfoDialog from './Components/InfoDialog/InfoDialog.js'
import InfoIcon from '@material-ui/icons/Info';
import { useDispatch, useSelector } from 'react-redux';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Gallery from './Pages/Gallery/Gallery.js'
import { addToCart } from './Store/Actions.js'

const App = (props) => {

  const [currentTab, setCurrentTab] = useState(1)
  const [showAddedIcon, setShowAddedIcon] = useState(false)
  const [isHelloOpen, setIsHelloOpen] = useState(true)
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)
  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()

  useEffect(() => {
    let savedCart = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('cart'))))
    console.log(savedCart)
    if (savedCart) {
      savedCart.forEach(item => {
        dispatch(addToCart(item.id, item.stripe, item.zipper, item.text, item.fabric, item.product, item.image, item.price))
      })
    }
  }, [])

  useEffect(() => {
    if (cart.length > 0) {
      setShowAddedIcon(true)
      setTimeout(() => setShowAddedIcon(false), 1000)
    }
  }, [cart])

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const Pages = [
    <Order openInfoDialogOpen={() => setIsInfoDialogOpen(true)}></Order>,
    <Catalog></Catalog>,
    <Gallery></Gallery>
  ]

  return (
    <div className="App">
      <HelloDialog open={isHelloOpen} onClose={() => setIsHelloOpen(false)} />
      <InfoDialog open={isInfoDialogOpen} onClose={() => setIsInfoDialogOpen(false)} />
      <Paper className='navbar-fixed' square>
        <div className='info-icon'>
          <InfoIcon onClick={() => setIsInfoDialogOpen(true)} />
        </div>
        <div className='sew-icon'>
          <img
            src={require('./assets/sewing.gif')}
          />
        </div>
        <img className='app-logo-img' src={Logo}></img>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="icon tabs example">
          {showAddedIcon ? <ShoppingBasketIcon className='show-added-icon'></ShoppingBasketIcon> : null}
          <Tab value={0} icon={<ShoppingBasketIcon />} />
          {cart.length > 0 ? <div className='cart-count'>
            <Avatar>
              {cart.length}
            </Avatar>
          </div> : null}
          <Tab value={1} onClick={() => dispatch(goToMain(true))} icon={<ViewHeadline />} />
          <Tab value={2} icon={<BurstModeIcon />} />
        </Tabs>
      </Paper>
      <div className='content'>
        {Pages[currentTab]}
      </div>
      <div id='rights'>
        © Website Designed & Powered By Miki Halamish
      </div>
    </div >
  );
}

export default App;