export const addToCart = (id, stripe, zipper, text, fabric, product, image, price) => ({
  type: 'ADD_TO_CART',
  id: id,
  stripe: stripe,
  zipper: zipper,
  text: text,
  fabric: fabric,
  product: product,
  image: image,
  price: price
})

export const removeFromCart = (id, text) => ({
  type: 'REMOVE_FROM_CART',
  id: id,
  text: text
})

export const goToMain = (flag) => ({
  type: 'GO_TO_MAIN',
  flag: flag
})