import './gallery.css'
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { CardActionArea, List, ListItem, Avatar, ListItemText } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '90%',
    },
}));

const PICTURES = [
    {
        img: require('../../assets/Gallery/1.jpeg'),
        cols: 2
    },
    {
        img: require('../../assets/Gallery/2.jpeg'),
    },
    {
        img: require('../../assets/Gallery/4.jpeg'),
        cols: 2
    },
    {
        img: require('../../assets/Gallery/5.jpeg'),
        cols: 2
    },
    {
        img: require('../../assets/Gallery/6.jpeg'),
    },
    {
        img: require('../../assets/Gallery/14.jpeg')
    },
    {
        img: require('../../assets/Gallery/7.jpeg'),
        cols: 2
    },
    {
        img: require('../../assets/Gallery/8.jpeg'),
    },
    {
        img: require('../../assets/Gallery/9.jpeg'),
        cols: 2
    },
    {
        img: require('../../assets/Gallery/10.jpeg'),
    },
    {
        img: require('../../assets/Gallery/11.jpeg'),
    },
    {
        img: require('../../assets/Gallery/12.jpeg'),
    },
    {
        img: require('../../assets/Gallery/13.jpeg'),
    },
    {
        img: require('../../assets/Gallery/14.jpeg')
    }
]

const Gallery = (props) => {

    const classes = useStyles();
    const [index, setIndex] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        var i, e, d = document, s = "script"; i = d.createElement("script"); i.async = 1;
        i.src = "https://cdn.curator.io/published/d24e2327-af54-44ae-9bfc-fc35da05292e.js";
        setTimeout(() => {
            e = d.getElementsByTagName(s)[0]; e.parentNode.insertBefore(i, e);
            setLoading(false)
        }, 2000)
    }, [])

    return (
        <div id='gallery'>
            {loading ? null : <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>}
            {loading ? <img style={{ width: '90%' }}
                src={require('../../assets/sewing.gif')}
            /> : null}
        </div >
    );
}

export default Gallery