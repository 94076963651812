import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent
} from '@material-ui/core'
import './helloDialog.css'

const HelloDialog = (props) => {

    return (
        <div className='hello-dialog'>
            <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>
                <DialogContent className='title-content'>
                    <Typography className='pink' variant="h6"> רקמה </Typography>
                    <Typography variant="h6"> ברוכים הבאים ל</Typography>
                </DialogContent>
                <DialogContent className='text-content' dividers>
                    <Typography gutterBottom>
                        היי!
                        אני תופרת ורוקמת באהבה דברים בעבודת יד
                    </Typography>
                    <Typography gutterBottom>
                        ♡ ושמחה מאוד לשתף אותם איתכם
                    </Typography>
                    <DialogContent className='text-content'>
                        <Typography gutterBottom>
                            דרך האתר תוכלו לבחור מבין עיצובים קיימים,
                            או לעצב את הרקמה האישית שלכם
                    </Typography>
                        <Typography>
                            ...בהקדשה למי שאתם אוהבים - או לעצמכם
                    </Typography>
                    </DialogContent>
                    <Typography className='pink'>
                        -  מיקי  -
                    </Typography>
                </DialogContent>
            </Dialog>
        </div >
    );
}

export default HelloDialog