const fabrics = [
    {
        name: 'beige',
        image: require('../assets/Templates/beige-fabric.png'),
        avilability: true,
    },
    {
        name: 'black',
        image: require('../assets/Templates/‏‏black-fabric.PNG'),
        avilability: true,
    },
    {
        name: 'gray',
        image: require('../assets/Templates/gray-fabric.jpg'),
        avilability: true,
    },
    {
        name: 'red',
        image: require('../assets/Templates/red-fabric.jpg'),
        avilability: true,
    },
    {
        name: 'orange',
        image: require('../assets/Templates/orange-fabric.jpg'),
        avilability: true,
    },
    {
        name: 'kanken',
        image: require('../assets/Products/brown-kanken.jpg'),
        avilability: true,
    },
    {
        name: 'hoop-beige',
        image: require('../assets/Templates/beige-fabric.jpg'),
        avilability: true,
    }
]

export default fabrics