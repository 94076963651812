
const stripes = [
    {
        name: 'red',
        image: require('../assets/Templates/red.png'),
        avilability: false
    },
    {
        name: 'blue',
        image: require('../assets/Templates/blue.png'),
        avilability: false
    },
    {
        name: 'pink',
        image: require('../assets/Templates/pink.png'),
        avilability: true
    },
    {
        name: 'light-blue',
        image: require('../assets/Templates/light-blue-stripe.png'),
        avilability: true
    },
    {
        name: 'black',
        image: require('../assets/Templates/‏‏black-stripe.PNG'),
        avilability: true
    },
    {
        name: 'shine-pink',
        image: require('../assets/Templates/shine-pink-stripe.png'),
        avilability: false
    },
    {
        name: 'gray',
        image: require('../assets/Templates/gray.png'),
        avilability: true
    },
    {
        name: 'beige',
        image: require('../assets/Templates/beige-stripe.jpg'),
        avilability: true
    },
    {
        name: 'purple',
        image: require('../assets/Templates/purple.png'),
        avilability: true
    },
    {
        name: 'tiger',
        image: require('../assets/Templates/tiger.jpg'),
        avilability: true
    },
    {
        name: 'flowers1',
        image: require('../assets/Products/black-flowers-1-kanken.jpg'),
        avilability: true
    },
    {
        name: 'flowers2',
        image: require('../assets/Products/black-flowers-2-kanken.jpg'),
        avilability: true
    },
    {
        name: 'mountain',
        image: require('../assets/Products/brown-kanken.jpg'),
        avilability: true
    },
    {
        name: 'yaara',
        image: require('../assets/Templates/yaara.jpeg'),
        avilability: true
    },
    {
        name: 'kersner',
        image: require('../assets/Templates/kersner.jpeg'),
        avilability: true
    },
    {
        name: 'shoshana',
        image: require('../assets/Templates/shoshana.jpeg'),
        avilability: true
    },
    {
        name: 'bar',
        image: require('../assets/Templates/bar.jpeg'),
        avilability: true
    },
    {
        name: 'bird',
        image: require('../assets/Templates/bird.jpeg'),
        avilability: true
    },
    {
        name: 'achinoam',
        image: require('../assets/Templates/achinoam.jpeg'),
        avilability: true
    },
    {
        name: 'shani',
        image: require('../assets/Templates/shani.jpeg'),
        avilability: true
    },
    {
        name: 'liat',
        image: require('../assets/Products/liat.jpeg'),
        avilability: true
    },
    {
        name: 'duba',
        image: require('../assets/Products/duba.jpeg'),
        avilability: true
    },
    {
        name: 'noa',
        image: require('../assets/Products/noa.jpeg'),
        avilability: true
    },
    {
        name: 'dror',
        image: require('../assets/Products/dror.jpeg'),
        avilability: true
    },
    {
        name: 'shoshi',
        image: require('../assets/Products/shoshi.jpeg'),
        avilability: true
    }
]

export default stripes