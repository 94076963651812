import './order.css'
import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'
import { removeFromCart } from '../../Store/Actions.js'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import OrderDialog from '../../Components/OrderDialog/OrderDialog.js'
import SuccessDialog from '../../Components/SuccessDialog/SuccessDialog.js'
import { useSelector, useDispatch } from 'react-redux';
import products from '../../Store/Products.js'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { addToCart } from '../../Store/Actions.js'
import { Fade, Grow } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const PRICE_PER_WORD = 5
const DIY_CODE = "888:"

const Order = (props) => {

    const classes = useStyles();
    const [isDetailsOpen, setDetailsOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const cart = useSelector(state => state.cart)
    const dispatch = useDispatch()

    const getTotalPrice = () => {
        let count = 0
        cart.map((item) => item.text ? count += (item.price + (item.text.split(' ').filter((word) => word != ' ').length * PRICE_PER_WORD)) * item.quantity : count += item.price * item.quantity)
        return count
    }

    return (
        <div id='order'>
            <OrderDialog
                onClose={() => setDetailsOpen(false)}
                open={isDetailsOpen}
                totalPrice={getTotalPrice}
                cart={cart}
                setSubmitted={setSubmitted}
                openInfoDialogOpen={props.openInfoDialogOpen}
            />
            {submitted ?
                <SuccessDialog
                    onClose={() => setSubmitted(false)}
                    open={submitted}
                /> :
                null}
            <Card>
                <CardHeader title='סיכום הזמנה' subheader={`סה"כ ${cart.length} פריטים`}>
                </CardHeader>
            </Card>
            <div className='products'>
                {
                    cart.length > 0 ? cart.map((product, i) => {
                        return (
                            product.id && !product.id.includes(DIY_CODE) ?
                                <Fade in={true}
                                    timeout={1000}>
                                    <div key={i} className='product'>
                                        <Card className={classes.root}>
                                            <CardHeader title={products.filter((item) => item.type == product.product)[0].singleHeb}>
                                            </CardHeader>
                                            <CardMedia
                                                className={classes.media}
                                                image={product.image}
                                            />
                                            {product.text ? <CardActions>
                                                <IconButton>
                                                    <TextFieldsIcon className='text' />
                                                </IconButton>
                                                <CardHeader className='text'
                                                    title={product.text}
                                                />
                                            </CardActions> : null}
                                            <CardActions disableSpacing>
                                                <IconButton onClick={() => dispatch(addToCart(product.id, product.stripe, product.zipper, product.text, product.fabric, product.product, product.image, product.price))}>
                                                    <AddCircleIcon />
                                                </IconButton>
                                                <CardHeader title={product.quantity + 'x'}></CardHeader>
                                                <IconButton onClick={() => dispatch(removeFromCart(product.id, product.text))}>
                                                    {product.quantity == 1 ? <RemoveShoppingCartIcon /> : <RemoveCircleIcon />}
                                                </IconButton>
                                            </CardActions>
                                            <CardActions>
                                                <CardHeader className='price'
                                                    title={`${product.text != null ? (product.price + product.text.split(' ').filter((word) => word != ' ').length * PRICE_PER_WORD) * product.quantity : product.price * product.quantity} ₪`}
                                                />
                                            </CardActions>
                                        </Card>
                                    </div>
                                </Fade> :
                                <div key={i} className='product'>
                                    <Card className={classes.root}>
                                        <CardHeader className='text'
                                            title='DIY'
                                        />
                                        {product.text ? <CardActions>
                                            <IconButton>
                                                <TextFieldsIcon className='text' />
                                            </IconButton>
                                            <CardHeader className='text'
                                                title={product.text}
                                            />
                                        </CardActions> : null}
                                        <CardActions disableSpacing>
                                            <IconButton onClick={() => dispatch(removeFromCart(product.id))}>
                                                <RemoveShoppingCartIcon />
                                            </IconButton>
                                            <CardHeader className='price'
                                                title={`${product.text != null ? product.price + product.text.split(' ').filter((word) => word != ' ').length * PRICE_PER_WORD : product.price} ₪`}
                                            />
                                        </CardActions>
                                    </Card>
                                </div>
                        )
                    }) : null
                }
            </div>
            <Card>
                <CardHeader title={`${getTotalPrice()} ₪`}>
                </CardHeader>
                <CardActions onClick={cart.length > 0 ? () => setDetailsOpen(true) : null} className={cart.length > 0 ? 'finish active' : 'finish'}>
                    <DoneAllIcon />
                </CardActions>
            </Card>
        </div>
    );
}

export default Order;