import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { blue, grey } from '@material-ui/core/colors';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TextField from '@material-ui/core/TextField';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook'
import './successDialog.css'
import { Link } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: grey[100],
        color: grey[600],
    },
});

export default function SuccessDialog(props) {

    return (
        <div className='order-details'>
            <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>
                <DialogTitle id="simple-dialog-title">!ההזמנה הושלמה בהצלחה</DialogTitle>
                <List>
                    <ListItem className='center' autoFocus button>
                        <ListItemText primary='אצור איתך קשר בהקדם להשלמת התהליך'></ListItemText>
                    </ListItem>
                    <ListItem className='center pink' autoFocus button>
                        <ListItemText primary='(: מיקי'></ListItemText>
                    </ListItem>
                    <ListItem onClick={props.onClose} className='center icons' autoFocus button>
                        <Avatar>
                            <ThumbUpAltIcon />
                        </Avatar>
                        <Avatar className='instagram'>
                            <Link href="https://www.instagram.com/_rikma/">
                                <InstagramIcon />
                            </Link>
                        </Avatar>
                        <Avatar className='facebook'>
                            <Link href="https://www.facebook.com/rikmathings/?ref=bookmarks">
                                <FacebookIcon />
                            </Link>
                        </Avatar>
                    </ListItem>
                </List>
            </Dialog>
        </div>
    );
}