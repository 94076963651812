const zippers = [
    {
        name: 'orange',
        color: 'rgb(255, 214, 51)'
    },
    {
        name: 'beige',
        color: 'rgb(255, 255, 230)'
    },
    {
        name: 'black',
        color: 'rgb(0, 0, 0)'
    },
    {
        name: 'blue',
        color: 'rgb(51, 204, 204)'
    },
    {
        name: 'purple',
        color: 'rgb(115, 38, 77)'
    },
    {
        name: 'red',
        color: 'rgb(153, 0, 51)'
    },
    {
        name: 'dark-blue',
        color: 'rgb(0, 0, 77)'
    }
]

export default zippers