import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { blue, grey } from '@material-ui/core/colors';
import DoneAllIcon from '@material-ui/icons/DoneAll'
import TextField from '@material-ui/core/TextField'
import CreateIcon from '@material-ui/icons/Create'
import './orderDialog.css'
import emailjs from 'emailjs-com'
import Radio from '@material-ui/core/Radio'
import { Typography, ListItemText, ListSubheader } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: grey[100],
        color: grey[600],
    },
});

const DELIVERY_PRICE = 30

export default function OrderDialog(props) {
    const classes = useStyles();
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [notes, setNotes] = useState('')
    const [flicker, setFlicker] = useState(false)
    const [isDelivery, setIsDelivery] = useState(false)
    const [address, setAddress] = useState('')
    const [discount, setDiscount] = useState(0)

    useEffect(() => {
        setFlicker(false)
    }, [name, phone])

    const calculateTotalPrice = () => {
        let final = props.totalPrice()
        if (isDelivery) {
            final += DELIVERY_PRICE
        }
        if (discount) {
            final = final - (final * discount)
        }
        return final
    }

    const sendOrder = () => {
        if (name.length == 0 || phone.length == 0 || (isDelivery && address.length == 0)) {
            setFlicker(true)
        } else {
            var template_params = {
                'name': name,
                'total_price': calculateTotalPrice(),
                'phone': phone,
                'cart': JSON.stringify(props.cart),
                'isDelivery': isDelivery,
                'address': address,
                'notes': notes
            }
            var serviceID = "rikma"
            var templateID = "template_lJcbYISF"
            emailjs.send(serviceID, templateID, template_params, 'user_E9tpQRoUT82V1n0fGQFOw')
            props.onClose()
            props.setSubmitted(true)
        }
    }

    return (
        <div className='order-details'>

            <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open={props.open}>

                <DialogTitle id="simple-dialog-title">:
                <InfoIcon style={{ position: 'absolute', left: '3%' }} onClick={props.openInfoDialogOpen} />השלמת הזמנה</DialogTitle>
                <List>
                    <ListItem className={name.length == 0 && flicker ? 'field flicker' : 'field'} button>
                        <Avatar className={classes.avatar}>
                            <PersonIcon />
                        </Avatar>
                        <TextField onChange={(e) => setName(e.target.value)} id="name" label="שם מלא" />
                    </ListItem>
                    <ListItem className={phone.length == 0 && flicker ? 'field flicker' : 'field'} button>
                        <Avatar className={classes.avatar}>
                            <PhoneIphoneIcon />
                        </Avatar>
                        <TextField onChange={(e) => setPhone(e.target.value)} id="phone" label="טלפון ליצירת קשר" />
                    </ListItem>
                    <ListItem className='field' button>
                        <Avatar className={classes.avatar}>
                            <CreateIcon />
                        </Avatar>
                        <TextField onChange={(e) => setNotes(e.target.value)} id="notes" label="הערות" />
                    </ListItem>
                    <ListItem className={isDelivery && address.length == 0 && flicker ? 'field flicker' : 'field'} style={{ alignItems: 'center' }} button>
                        <Checkbox
                            checked={isDelivery}
                            onChange={() => setIsDelivery(!isDelivery)}
                            value="a"
                            name="radio-button-demo"
                        />
                        {
                            isDelivery ?
                                <TextField onChange={(e) => setAddress(e.target.value)} id="delivery" label="הכנס כתובת מלאה" />
                                : <ListItemText style={{ textAlign: 'right' }} id="delivery">הוספת משלוח לאיזור ת"א והמרכז ב ₪30</ListItemText>
                        }
                    </ListItem>
                    <ListItem className='center price'>
                        {/* <ListItemText className='discount'>-10%</ListItemText> */}
                        <ListItemText className='total'>{calculateTotalPrice()}₪</ListItemText>
                    </ListItem>
                    <ListItem className='center' autoFocus button>
                        <Avatar onClick={sendOrder} style={{ backgroundColor: 'rgb(2366,10,107)' }}>
                            <DoneAllIcon />
                        </Avatar>
                    </ListItem>
                </List>
            </Dialog>
        </div>
    );
}