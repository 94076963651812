const products = [
    {
        type: 'clutch',
        image: require('../assets/Products/clutch3.jpg'),
        title: "'תיקי קלאץ",
        description: 'לא חצי',
        height: 10,
        width: 15,
        price: 50,
        isZipper: true,
        singleHeb: "'תיק קלאץ"
    },
    {
        type: 'wallet',
        image: require('../assets/Products/baige-tiger-wallet.jpg'),
        title: "ארנקים",
        description: 'אם אין כסף, לפחות יש רקמה',
        height: 10,
        width: 15,
        price: 40,
        isZipper: true,
        singleHeb: 'ארנק'
    },
    {
        type: 'pakal',
        image: require('../assets/Products/pink-pakal1.jpg'),
        title: "פקל רקמה",
        description: 'לתותחים שמדכאים',
        height: 10,
        width: 15,
        price: 80,
        isZipper: true,
        singleHeb: 'פקל רקמה'
    },
    {
        type: 'tobacco',
        image: require('../assets/Products/gray-tobacco.jpg'),
        title: "קייסים לטבק",
        description: 'מחקרים הראו כי קייס לטבק אינו פוגע בבריאות',
        height: 10,
        width: 15,
        price: 80,
        isZipper: true,
        singleHeb: 'קייס לטבק'
    },
    {
        type: 'hoops',
        image: require('../assets/Templates/kersner.jpeg'),
        title: "חישוקי רקמה",
        description: 'עגול ורקום',
        height: 22,
        width: 22,
        price: 0,
        isZipper: false,
        singleHeb: 'חישוק רקמה'
    },
    {
        type: 'hogron',
        image: require('../assets/Products/gray-hogron.jpg'),
        title: "חוגרונים",
        description: 'העיקר שהפז"ם דופק',
        height: 10,
        width: 15,
        price: 50,
        isZipper: false,
        singleHeb: 'חוגרון'
    },
    {
        type: 'pencil-case',
        image: require('../assets/Products/shine-pink-black-pencil-case.jpg'),
        title: "קלמרים",
        description: 'לתקופת מבחנים או לקשקושים',
        height: 10,
        width: 15,
        price: 50,
        isZipper: true,
        singleHeb: 'קלמר'
    },
    {
        type: 'bags',
        image: require('../assets/Products/bag-red-beige.jpeg'),
        title: "תיקים",
        description: 'מדליקים',
        height: 10,
        width: 15,
        price: 60,
        isZipper: true,
        singleHeb: 'תיק'
    },
    {
        type: 'earpods',
        image: require('../assets/Products/beige-black-earpods.jpg'),
        title: "קייסים לאוזניות",
        description: 'קטנים ומתוקים',
        height: 10,
        width: 15,
        price: 30,
        isZipper: true,
        singleHeb: 'קייס לאוזניות'
    },
    {
        type: 'more',
        image: require('../assets/Products/brown-kanken.jpg'),
        title: "אחר",
        description: 'אחר',
        height: 0,
        width: 0,
        singleHeb: 'אחר'
    }
]

export default products