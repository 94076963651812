const allItems = [
    {
        id: 'id',
        type: 'clutch',
        image: require('../assets/Products/clutch3.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'blue',
        price: 50
    },
    {
        id: '2',
        type: 'clutch',
        image: require('../assets/Products/clutch2.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'pink',
        price: 50
    },
    {
        id: '3',
        type: 'clutch',
        image: require('../assets/Products/clutch1.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'red',
        price: 50
    },
    {
        id: '4',
        type: 'clutch',
        image: require('../assets/Products/black-clutch.jpg'),
        fabric: 'beige',
        zipper: 'black',
        stripe: 'black',
        price: 50
    },
    {
        id: '5',
        type: 'wallet',
        image: require('../assets/Products/wallet1.jpg'),
        fabric: 'beige',
        zipper: 'black',
        stripe: 'black',
        price: 40
    },
    {
        id: '6',
        type: 'hogron',
        image: require('../assets/Products/light-blue-hogron.jpg'),
        fabric: 'black',
        zipper: null,
        stripe: 'light-blue',
        price: 50
    },
    {
        id: '7',
        type: 'pencil-case',
        image: require('../assets/Products/light-blue-pencil-case.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'light-blue',
        price: 50
    },
    {
        id: '8',
        type: 'pencil-case',
        image: require('../assets/Products/shine-pink-black-pencil-case.jpg'),
        fabric: 'black',
        zipper: 'beige',
        stripe: 'shine-pink',
        price: 50
    },
    {
        id: '9',
        type: 'pencil-case',
        image: require('../assets/Products/gray-black-pencil-case.jpg'),
        fabric: 'black',
        zipper: 'black',
        stripe: 'gray',
        price: 50
    },
    {
        id: '10',
        type: 'tobacco',
        image: require('../assets/Products/gray-tobacco.jpg'),
        fabric: 'gray',
        zipper: 'black',
        stripe: 'black',
        price: 80
    },
    {
        id: '11',
        type: 'tobacco',
        image: require('../assets/Products/beige-black-tobacco.jpg'),
        fabric: 'beige',
        zipper: null,
        stripe: 'black',
        price: 80
    },
    {
        id: '12',
        type: 'pakal',
        image: require('../assets/Products/pink-pakal.jpg'),
        fabric: 'gray',
        zipper: 'beige',
        stripe: 'pink',
        price: 80
    },
    {
        id: '13',
        type: 'wallet',
        image: require('../assets/Products/pink-wallet.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'pink',
        price: 40
    },
    {
        id: '14',
        type: 'hogron',
        image: require('../assets/Products/gray-hogron.jpg'),
        fabric: 'black',
        zipper: null,
        stripe: 'gray',
        price: 50
    },
    {
        id: '15',
        type: 'wallet',
        image: require('../assets/Products/purple-wallet.jpg'),
        fabric: 'beige',
        zipper: 'purple',
        stripe: 'purple',
        price: 40
    },
    {
        id: '16',
        type: 'wallet',
        image: require('../assets/Products/orange-light-blue-wallet.jpg'),
        fabric: 'orange',
        zipper: 'beige',
        stripe: 'light-blue',
        price: 40
    },
    {
        id: '17',
        type: 'wallet',
        image: require('../assets/Products/red-beige-wallet.jpg'),
        fabric: 'red',
        zipper: 'beige',
        stripe: 'beige',
        price: 40
    },
    {
        id: '18',
        type: 'tobacco',
        image: require('../assets/Products/red-beige-tobacco.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'red',
        price: 80
    },
    {
        id: '19',
        type: 'wallet',
        image: require('../assets/Products/blue-black-wallet.jpg'),
        fabric: 'beige',
        zipper: 'blue',
        stripe: 'black',
        price: 40
    },
    {
        id: '20',
        type: 'earpods',
        image: require('../assets/Products/beige-black-earpods.jpg'),
        fabric: 'beige',
        zipper: 'black',
        stripe: 'black',
        price: 30
    },
    {
        id: '21',
        type: 'earpods',
        image: require('../assets/Products/gray-black-earpods.jpg'),
        fabric: 'black',
        zipper: null,
        stripe: 'gray',
        price: 30
    },
    {
        id: '22',
        type: 'earpods',
        image: require('../assets/Products/beige-blue-earpods.jpg'),
        fabric: 'beige',
        zipper: null,
        stripe: 'blue',
        price: 30
    },
    {
        id: '23',
        type: 'more',
        image: require('../assets/Products/brown-kanken.jpg'),
        fabric: 'kanken',
        zipper: null,
        stripe: 'mountain',
        price: 50
    },
    {
        id: '24',
        type: 'more',
        image: require('../assets/Products/black-flowers-1-kanken.jpg'),
        fabric: 'kanken',
        zipper: null,
        stripe: 'flowers1',
        price: 50
    },
    {
        id: '25',
        type: 'more',
        image: require('../assets/Products/black-flowers-2-kanken.jpg'),
        fabric: 'kanken',
        zipper: null,
        stripe: 'flowers2',
        price: 50
    },
    {
        id: '26',
        type: 'bags',
        image: require('../assets/Products/baige-light-blue-bag.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'light-blue',
        price: 60
    },
    {
        id: '27',
        type: 'bags',
        image: require('../assets/Products/baige-black-bag.jpg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'black',
        price: 60
    },
    {
        id: '28',
        type: 'tobacco',
        image: require('../assets/Products/baige-blue-tobacco.jpg'),
        fabric: 'beige',
        zipper: 'gray',
        stripe: 'light-blue',
        price: 80
    },
    {
        id: '29',
        type: 'wallet',
        image: require('../assets/Products/baige-blue-wallet.jpg'),
        fabric: 'beige',
        zipper: 'white',
        stripe: 'light-blue',
        price: 40
    },
    {
        id: '30',
        type: 'wallet',
        image: require('../assets/Products/black-blue-gray-wallet.jpg'),
        fabric: 'black',
        zipper: 'black',
        stripe: 'gray',
        price: 40
    },
    {
        id: '31',
        type: 'wallet',
        image: require('../assets/Products/baige-tiger-wallet.jpg'),
        fabric: 'beige',
        zipper: 'white',
        stripe: 'tiger',
        price: 40
    },
    {
        id: '32',
        type: 'pakal',
        image: require('../assets/Products/pakal-beige-blue-grey.jpeg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'gray',
        price: 80
    },
    {
        id: '33',
        type: 'pakal',
        image: require('../assets/Products/pakal-beige-red.jpeg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'red',
        price: 80
    },
    {
        id: '34',
        type: 'bags',
        image: require('../assets/Products/bag-red-beige.jpeg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'red',
        price: 60
    },
    {
        id: '35',
        type: 'wallet',
        image: require('../assets/Products/wallet-beige-red.jpeg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'red',
        price: 40
    },
    {
        id: '36',
        type: 'tobacco',
        image: require('../assets/Products/tobbaco-black-purple.jpeg'),
        fabric: 'black',
        zipper: 'black',
        stripe: 'purple',
        price: 80
    },
    {
        id: '37',
        type: 'wallet',
        image: require('../assets/Products/wallet-beige-blue.jpeg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'blue',
        price: 40
    },
    {
        id: '38',
        type: 'pencil-case',
        image: require('../assets/Products/pencil-case-beige-blue.jpeg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'blue',
        price: 50
    },
    {
        id: '39',
        type: 'hogron',
        image: require('../assets/Products/hogron-black-pink.jpeg'),
        fabric: 'black',
        zipper: null,
        stripe: 'shine-pink',
        price: 50
    },
    {
        id: '40',
        type: 'tobacco',
        image: require('../assets/Products/tobacco-black-red.jpeg'),
        fabric: 'black',
        zipper: 'black',
        stripe: 'red',
        price: 80
    },
    {
        id: '41',
        type: 'earpods',
        image: require('../assets/Products/beige-red-earpods.jpeg'),
        fabric: 'beige',
        zipper: 'beige',
        stripe: 'red',
        price: 30
    },
    {
        id: '42',
        type: 'hoops',
        image: require('../assets/Templates/yaara.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'yaara',
        price: 0
    },
    {
        id: '43',
        type: 'hoops',
        image: require('../assets/Templates/kersner.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'kersner',
        price: 0
    },
    {
        id: '44',
        type: 'hoops',
        image: require('../assets/Templates/shoshana.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'shoshana',
        price: 0
    },
    {
        id: '45',
        type: 'hoops',
        image: require('../assets/Templates/bar.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'bar',
        price: 0
    },
    {
        id: '46',
        type: 'hoops',
        image: require('../assets/Templates/bird.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'bird',
        price: 0
    },
    {
        id: '47',
        type: 'hoops',
        image: require('../assets/Templates/achinoam.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'achinoam',
        price: 0
    },
    {
        id: '48',
        type: 'hoops',
        image: require('../assets/Templates/shani.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'shani',
        price: 0
    },
    {
        id: '49',
        type: 'hoops',
        image: require('../assets/Products/liat.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'liat',
        price: 0
    },
    {
        id: '50',
        type: 'hoops',
        image: require('../assets/Products/duba.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'duba',
        price: 0
    },
    {
        id: '51',
        type: 'hoops',
        image: require('../assets/Products/noa.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'noa',
        price: 0
    },
    {
        id: '52',
        type: 'hoops',
        image: require('../assets/Products/dror.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'dror',
        price: 0
    },
    {
        id: '53',
        type: 'hoops',
        image: require('../assets/Products/shoshi.jpeg'),
        fabric: 'hoop-beige',
        zipper: null,
        stripe: 'shoshi',
        price: 0
    },
    {
        id: '54',
        type: 'tobacco',
        image: require('../assets/Products/my-shimen.jpeg'),
        fabric: 'black',
        zipper: 'black',
        stripe: 'black',
        price: 0
    },
    {
        id: '55',
        type: 'tobacco',
        image: require('../assets/Products/black-gray-tobacco.jpeg'),
        fabric: 'black',
        zipper: 'black',
        stripe: 'gray',
        price: 0
    }
]

export default allItems